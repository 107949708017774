<template>
  <clip
    class="header part relative"
    :class="[isOrange ? 'bg-primary' : 'bg-secondary', payload.optionen]"
    :clip-bottom="payload.clipbottom"
    :clip-bottom-mobile="payload.clipbottommobile"
  >
    <Image v-view class="hero-image animate in-bottom" :src="payload.bild" />
    <div v-view class="constrain hidden md:block animate in-bottom">
      <div class="row-12 main-row">
        <div v-view class="col-12 md:col-6 md:offset-1 animate">
          <h1 v-text-split="payload.text" class="text-white stagger hero-text" />
        </div>
      </div>
    </div>
  </clip>
</template>

<script>
import { computed } from 'vue';
import Container from '../utils/Container.vue';

export default {
  components: { Container },
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    return {
      isOrange: computed(() => props.payload.hintergrund.includes('orange')),
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.header {
  position: relative;
  display: flex;
  z-index: 15;

  @include responsive('padding-top', 0, px(128));
  @include responsive('height', 80vh, 100%);

  @screen md {
    min-height: 80vh;
  }

  &.textBottom .main-row > * {
    display: flex;
    align-items: flex-end;
    padding-bottom: px(200);
  }

  &.textMiddle .main-row > * {
    display: flex;
    align-items: center;
  }

  &.textTop .main-row > * {
    display: flex;
    align-items: flex-start;
    padding-top: px(100);

    @screen lg {
      padding-top: px(50);
    }
  }
}

.hero-image {
  @apply absolute h-full w-full top-0 bottom-0 right-0 left-0 object-cover;
}

.hero-text {
  @apply text-xl;
  line-height: px(42);
  margin-bottom: px(24);

  @screen lg {
    font-size: 2.5rem;
    line-height: px(68);
    margin-bottom: px(20);
  }

  @screen xl {
    @apply text-xxl;
    line-height: px(68);
    margin-bottom: px(20);
  }
}

.main-row {
  @apply h-full;

  position: relative;

  .header.left & {
    max-height: none;
  }
}

.content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 1;
}

.header:not(.left) {
  .constrain {
    padding-right: 0;
    height: 100%;

    .content {
      padding-right: var(--content-padding);
    }
  }
}

@screen md {
  .constrain {
    margin-left: auto;
  }
}
</style>
